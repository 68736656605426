<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-11-11 14:16:04
 * @LastEditTime: 2022-11-11 15:07:28
 * @Description: 输入账号
 * @FilePath: \BusinessPlatform\src\views\login\components\resetPassword\accountNumber.vue
 -->


<template>
    <div class="accountNumber">
        <h4>输入账号</h4>
        <p>请输入要重置密码的手机号</p>
        <div>
            <el-form
                ref="ref_phoneNumber"
                class="form-inline"
                :rules="rules"
                :inline="true"
                :model="formInline"
            >
                <el-form-item prop="phoneNumber">
                    <el-input v-model="formInline.phoneNumber" placeholder="请输入账号" clearable>
                        <i slot="prefix" class="iconfont iconshouji"></i>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" class="loginBtn" @click="nextStep">
            下 一 步
        </el-button>
    </div>
</template>

<script>
export default {
    name: 'account-number',
    components: {},
    data() {
        return {
            formInline: {
                phoneNumber: '',
            },
            rules: {
                phoneNumber: [{
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur',
                }, {
                    pattern: /^1[3-9]\d{9}$/,
                    message: '手机号格式错误',
                    trigger: 'blur',
                }],
            },
        };
    },
    watch: {},
    created() {},
    mounted() {},
    computed: {},
    methods: {
        nextStep() {
            this.$refs.ref_phoneNumber.validate(async valid => {
                if (valid) {
                    const nextStepData = {
                        stepNo: 1,
                        phoneNumber: this.formInline.phoneNumber,
                    };
                    this.$emit('nextStep', nextStepData);
                }
            });
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.accountNumber
    h4
        font-size 0.32rem
        color #001A2C
        margin-bottom: 0.1rem
    >p
        font-size 0.16rem
        line-height: 0.56rem
        color #001A2C
    >div
        height 1.3rem
        >>>.el-form
            .el-form-item
                width 100%
                margin: 0
                .el-form-item__content
                    width 100%
    .loginBtn
        width 100%
</style>
